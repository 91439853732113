import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(#e5e5e5, #fff);
  position: relative;
`;

export const ContentContainer = styled.div`
  width: 60vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 0 auto;

  @media (max-width: 1500px) {
    width: 80vw;
  }

  @media (max-width: 850px) {
    width: 90vw;
  }
`;

export const CardsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5rem;

  @media (max-width: 1500px) {
    width: 90%;
  }
`;
