import { Button, TextField } from "@mui/material";
import imageCompression from "browser-image-compression";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckSession, GetSession } from "../../../utils/CheckSession";
import { Container, FileInputImage, FileInputLabel, StyledForm, StyledLabel, StyledSelect } from "./styled";

export const NewMachine = () => {
  const navigate = useNavigate();
  const [hiddenButtonText, setHiddenButtonText] = useState("");
  const [deletedButtonText, setDeletedButtonText] = useState("");
  const [viewImage, setViewImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [searchparams] = useSearchParams();
  const id = searchparams.get("id");
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      year: "",
      liftHeight: "",
      motorHours: "",
      passingHeight: "",
      drive: "Vyberte pohon vozíku",
      weight: "",
      loadCapacity: "",
      rentPrice: "",
      salePrice: "",
      description: "",
      machineNumber: null,
      machineType: "Vyberte typ vozíku",
      forks: "",
      mastType: "",
      additionalAttributes: "",
      equipment: "",
      engine: "",
      hydraulicControl: "",
      driveControl: "",
      cabin: "",
      lights: "",
      battery: "",
      viewFile: "",
      otherFiles: [],
      accessories1: "",
      accessories2: "",
      accessories3: "",
      accessories4: "",
      accessories5: "",
      accessories6: "",
      accessories7: "",
      accessories8: "",
      accessories9: "",
      accessories10: "",
    },
  });

  //getting machine data if is created
  const getMachineData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/machine/admin/get-machineByID`, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + GetSession(),
        },
      });
      if (res.status === 200) {
        let data = await res.json();
        setValue("id", data.machine.id.toString());
        setValue("name", data.machine.name);
        setValue("year", data.machine.year.toString());
        setValue("liftHeight", data.machine.liftHeight.toString());
        setValue("motorHours", data.machine.motorHours ? data.machine.motorHours.toString() : "0");
        data.machineSpecification[0].passingHeight && setValue("passingHeight", data.machineSpecification[0].passingHeight.toString());
        setValue("drive", data.machine.drive);
        setValue("weight", data.machine.weight.toString());
        setValue("loadCapacity", data.machine.loadCapacity.toString());
        data.machine.rentPrice && setValue("rentPrice", data.machine.rentPrice.toString());
        setValue("salePrice", data.machine.salePrice?.toString());
        setValue("description", data.machine.description);
        setValue("internalDescription", data.machine.internalDescription);
        setValue("machineNumber", data.machineSpecification[0].machineNumber);
        setValue("machineType", data.machineSpecification[0].machineType);
        setValue("forks", data.machineSpecification[0].forks);
        setValue("mastType", data.machineSpecification[0].mastType);
        setValue("additionalAttributes", data.machineSpecification[0].additionalAttributes);
        setValue("equipment", data.machineSpecification[0].equipment);
        setValue("engine", data.machineSpecification[0].engine);
        setValue("hydraulicControl", data.machineSpecification[0].hydraulicControl);
        setValue("driveControl", data.machineSpecification[0].driveControl);
        setValue("cabin", data.machineSpecification[0].cabin);
        setValue("lights", data.machineSpecification[0].lights);
        setValue("battery", data.machineSpecification[0].battery);
        setHiddenButtonText(data.machine.hidden);
        setDeletedButtonText(data.machine.deleted);
        for (let i = 0; i <= data.machine.numberOfImages; i++) {
          if (i === 0) {
            setViewImage(`https://storage.googleapis.com/prezentation_web_storage/${data.machine.id}/${i}`);
          } else {
            setOtherImages([...otherImages], otherImages.push(`https://storage.googleapis.com/prezentation_web_storage/${data.machine.id}/${i}`));
          }
        }

        for (let index = 1; index < 11; index++) {
          if (data.machineAccessories[0]["name" + index] === undefined) {
            setValue("accessories" + index, "");
          } else {
            setValue("accessories" + index, data.machineAccessories[0]["name" + index]);
          }
        }
      } else {
        alert("Něco se nepovedlo");
      }
    } catch (e) {
      console.error(e);
      alert(e);
    }
  };

  useEffect(() => {
    if (id !== null) {
      getMachineData();
    }
  }, []);

  //Generating images view on change
  const onImageChange = async (event, type) => {
    if (type === "viewImage" && event.target.files && event.target.files[0]) {
      setViewImage("");
      setViewImage(URL.createObjectURL(event.target.files[0]));
    }

    if (type === "otherImages" && event.target.files && event.target.files[0]) {
      setOtherImages([]);
      Object.keys(event.target.files).forEach((index) => {
        setOtherImages((otherImages) => [...otherImages, URL.createObjectURL(event.target.files[index])]);
      });
    }

    if (type === "viewImage" && !event.target.files[0]) {
      setViewImage("");
    } else if (type === "otherImages" && !event.target.files[0]) {
      setOtherImages([]);
    }
  };

  const onSubmit = async (data) => {
    if (!id && !data.viewFile) {
      alert("Musíte přidat náhledový obrázek!");
      return;
    }
    if (!id && data.viewFile.length === 0) {
      alert("Musíte přidat náhledový obrázek!");
      return;
    }
    if (!id && !data.otherFiles) {
      alert(`Musíte přidat "ostatní" obrázky!`);
      return;
    }
    if (!id && data.otherFiles.length === 0) {
      alert(`Musíte přidat "ostatní" obrázky!`);
      return;
    }

    async function compressImages(image) {
      const imageFile = image;

      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
    }

    async function sendImgs(id) {
      if (data.otherFiles[0] && data.viewFile) {
        const body = new FormData();
        let xhrObj = new XMLHttpRequest();
        xhrObj.open("POST", `${process.env.REACT_APP_URL}/machine/admin/upload-image`, true);

        body.append("viewImage", await compressImages(data.viewFile[0]));

        var fakePromise = new Promise((resolve, reject) => {
          Object.values(data.otherFiles).forEach(async (image) => {
            const compressedImage = await compressImages(image);
            body.append("otherImages", compressedImage);
            if (body.getAll("otherImages").length === data.otherFiles.length) resolve();
          });
        });

        fakePromise.then(() => {
          body.append("id", id);
          xhrObj.send(body);

          let response;
          const finalResponse = new Promise((r) => (response = r));

          xhrObj.onreadystatechange = () => {
            if (xhrObj.readyState === XMLHttpRequest.DONE && xhrObj.status === 200) {
              response(xhrObj);
              alert("Stroj byl úspěšně přidán / aktualizován");
            }
          };
          console.log(finalResponse);
        });
      } else {
        alert("Stroj byl úspěšně přidán / aktualizován");
      }
    }

    if (!data.salePrice) data.salePrice = "0";

    fetch(`${process.env.REACT_APP_URL}/machine/admin/add-machine`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + GetSession(),
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((id) => {
        sendImgs(id);
      })
      .catch((e) => alert(e));
  };

  const HideMachine = async (id) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/machine/admin/hide-machine`, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + GetSession(),
        },
      });
      if (res.status === 200) {
        alert("Akce proběhla úspěšně");
      }
    } catch (e) {
      console.error(e);
      alert("Něco se nepovedlo");
    }
  };

  const RemoveMachine = async (id) => {
    if (window.confirm(`Opravdu chcete tento stroj ${deletedButtonText ? "obnovit" : "vymazat"}?`)) {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/machine/admin/${deletedButtonText ? "retrieve-machine" : "remove-machine"}`, {
          method: "POST",
          body: JSON.stringify({ id: id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + GetSession(),
          },
        });
        if (res.status === 200) {
          alert(`Stroj byl úspěšně ${deletedButtonText ? "obnoven" : "vymazán"}`);
          navigate(`/administration/${deletedButtonText ? "machines/deleted-machines" : "machines"}`);
        }
      } catch (e) {
        console.error(e);
        alert("Něco se nepovedlo");
      }
    }
  };

  const RemoveMachineFromDb = async (id) => {
    if (window.confirm(`Opravdu chcete tento stroj vymazat z databáze?`)) {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/machine/admin/remove-machine-from-db`, {
          method: "POST",
          body: JSON.stringify({ id: id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + GetSession(),
          },
        });
        if (res.status === 200) {
          alert(`Stroj byl úspěšně vymazán z databáze`);
          navigate(`/administration/machines/deleted-machines`);
        }
      } catch (e) {
        console.error(e);
        alert("Něco se nepovedlo");
      }
    }
  };

  return (
    CheckSession() && (
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <h1 style={{ marginBottom: "1rem" }}>Základní údaje</h1>
          <StyledLabel>
            Název stroje<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="name" type={"text"} variant="outlined" required fullWidth {...register("name")} placeholder="BT RWE120"></TextField>
          <StyledLabel>
            Rok výroby<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="year" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" required fullWidth {...register("year")} placeholder="2012"></TextField>
          <StyledLabel>
            Výška zdvihu(mm)<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="liftHeight" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" required fullWidth {...register("liftHeight")} placeholder="3350"></TextField>
          <StyledLabel>
            Motohodiny<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="motorHours" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" required fullWidth {...register("motorHours")} placeholder="970"></TextField>
          <StyledLabel>
            Pohon<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledSelect name="drive" required {...register("drive")}>
            <option value={"Vyberte pohon vozíku"} hidden>
              Vyberte pohon vozíku
            </option>
            <option value={"AKU"}>AKU</option>
            <option value={"LPG"}>LPG</option>
            <option value={"Diesel"}>Diesel</option>
          </StyledSelect>
          <StyledLabel>
            Hmotnost(Kg)<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="weight" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("weight")} placeholder="1750"></TextField>
          <StyledLabel>
            Nosnost(Kg)<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="loadCapacity" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("loadCapacity")} placeholder="1125"></TextField>
          <StyledLabel>Cena pronájmu(den/Kč)</StyledLabel>
          <TextField name="rentPrice" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" fullWidth {...register("rentPrice")} placeholder="219"></TextField>
          <StyledLabel>Cena prodeje(Kč)</StyledLabel>
          <TextField name="salePrice" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" fullWidth {...register("salePrice")} placeholder="114000"></TextField>
          <StyledLabel>
            Popis vozíku<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="description" style={{ marginBottom: "15px" }} type={"text"} multiline variant="outlined" required fullWidth {...register("description")}></TextField>
          <StyledLabel>Interní popis vozíku (nebude zobrazen zákazníkům)</StyledLabel>
          <TextField name="description" style={{ marginBottom: "15px" }} type={"text"} multiline variant="outlined" fullWidth {...register("internalDescription")}></TextField>
          <h1 style={{ marginTop: "2rem" }}>Technická specifikace</h1>
          {getValues().machineNumber && (
            <>
              <StyledLabel style={{ color: "grey" }}>Číslo stroje</StyledLabel>
              <TextField name="machineNumber" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("machineNumber")} disabled></TextField>
            </>
          )}
          <StyledLabel>
            Typ stroje<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <StyledSelect name="machineType" required {...register("machineType")}>
            <option value={"Vyberte typ vozíku"} hidden>
              Vyberte typ vozíku
            </option>
            <option value={"Čelní vozík"}>Čelní vozík</option>
            <option value={"Retrak"}>Retrak</option>
            <option value={"Ručně vedený"}>Ručně vedený</option>
          </StyledSelect>
          <StyledLabel>Průjezdová výška(mm)</StyledLabel>
          <TextField name="passingHeight" style={{ marginBottom: "15px" }} type={"number"} variant="outlined" fullWidth {...register("passingHeight")} placeholder="2200"></TextField>
          <StyledLabel>
            Vidlice(mm)<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="forks" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("forks")} placeholder="1200"></TextField>
          <StyledLabel>Typ stožáru</StyledLabel>
          <TextField
            name="mastType"
            style={{ marginBottom: "15px" }}
            type={"text"}
            variant="outlined"
            fullWidth
            {...register("mastType")}
            placeholder="STD duplex stožár bez volného zdvihu"
          ></TextField>
          <StyledLabel>Přídavné zařízení</StyledLabel>
          <TextField
            name="additionalAttributes"
            style={{ marginBottom: "15px" }}
            type={"text"}
            variant="outlined"
            fullWidth
            {...register("additionalAttributes")}
            placeholder="Boční posuv, náklon vidlic, výsuv stožáru"
          ></TextField>
          <StyledLabel>Vybavení</StyledLabel>
          <TextField name="equipment" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("equipment")} placeholder="Plošinka, ochranná madla"></TextField>
          <StyledLabel>
            Motor<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField
            name="engine"
            style={{ marginBottom: "15px" }}
            type={"text"}
            variant="outlined"
            required
            fullWidth
            {...register("engine")}
            placeholder="Silný, spolehlivý elektromotor"
          ></TextField>
          <StyledLabel>
            Ovládání hydrauliky<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField
            name="hydraulicControl"
            style={{ marginBottom: "15px" }}
            type={"text"}
            variant="outlined"
            required
            fullWidth
            {...register("hydraulicControl")}
            placeholder="Elektronická kolébka na ojce"
          ></TextField>
          <StyledLabel>
            Ovládání směru jízdy<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField
            name="driveControl"
            style={{ marginBottom: "15px" }}
            type={"text"}
            variant="outlined"
            required
            fullWidth
            {...register("driveControl")}
            placeholder="Motýlek ovládací oje"
          ></TextField>
          <StyledLabel>
            Kabina<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="cabin" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("cabin")} placeholder="NE"></TextField>
          <StyledLabel>
            Světla<span style={{ color: "red" }}>*</span>
          </StyledLabel>
          <TextField name="lights" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" required fullWidth {...register("lights")} placeholder="ANO"></TextField>
          <StyledLabel>Baterie</StyledLabel>
          <TextField name="battery" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("battery")} placeholder="Repasovaná"></TextField>
          <h1 style={{ marginTop: "2rem" }}>Příslušenství</h1>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories1" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories1")} placeholder="Nabíječ 24V"></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories2" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories2")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories3" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories3")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories4" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories4")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories5" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories5")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories6" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories6")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories7" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories7")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories8" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories8")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories9" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories9")}></TextField>
          <StyledLabel>Název příslušenství</StyledLabel>
          <TextField name="accessories10" style={{ marginBottom: "15px" }} type={"text"} variant="outlined" fullWidth {...register("accessories10")}></TextField>
          {id && (
            <p style={{ color: "#E00B0B", fontSize: "13px" }}>
              Pokud chcete změnit/přidat/odebrat obrázky, nahrajte znovu všechny obrázky stroje pro správné fungování systému (vedle tlačítka s textem "vybrat soubor" musíte vidět název nově nahraného
              obrázku, nebo počet nově nahraných souborů)
            </p>
          )}
          <FileInputLabel>Náhledová fotka</FileInputLabel>
          <input style={{ display: "block" }} accept="image/jpeg" type="file" {...register("viewFile")} onChange={(e) => onImageChange(e, "viewImage")} />
          {viewImage && <FileInputImage src={viewImage} alt="preview" />}
          <FileInputLabel>Ostatní fotky</FileInputLabel>
          <input style={{ display: "block" }} accept="image/jpeg" type="file" multiple {...register("otherFiles")} onChange={(e) => onImageChange(e, "otherImages")} />
          {otherImages[0] && otherImages.map((image, index) => <FileInputImage key={index} src={image} alt="preview" />)}
          <Button type="submit" variant="contained" style={{ width: "100%", backgroundColor: "#262626", marginTop: "20px", transition: "0.2s" }}>
            Odeslat
          </Button>
          {getValues().id && (
            <>
              <Button type="button" onClick={() => HideMachine(getValues().id)} variant="contained" style={{ width: "100%", backgroundColor: "#262626", marginTop: "20px", transition: "0.2s" }}>
                {hiddenButtonText ? <>Zobrazit stroj</> : <>Schovat stroj</>}
              </Button>
              <Button type="button" onClick={() => RemoveMachine(getValues().id)} variant="contained" style={{ width: "100%", backgroundColor: "#262626", marginTop: "20px", transition: "0.2s" }}>
                {deletedButtonText ? <>Obnovit stroj</> : <>Vymazat stroj</>}
              </Button>
              {deletedButtonText && (
                <Button
                  type="button"
                  onClick={() => RemoveMachineFromDb(getValues().id)}
                  variant="contained"
                  style={{ width: "100%", backgroundColor: "#262626", marginTop: "20px", transition: "0.2s" }}
                >
                  Vymazat stroj z databáze
                </Button>
              )}
            </>
          )}
        </StyledForm>
      </Container>
    )
  );
};

export default NewMachine;
