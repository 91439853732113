import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
`;

export const AddNewMachineCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 560px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  transition: 0.2s;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const PropertyHeader = styled.h4`
  font-size: 12px;
  color: #757575;
  font-weight: 500;
  margin: 0;
`;

export const PropertyText = styled.p`
  color: #262626;
  font-size: 16px;
  margin: 0;
`;

export const FilterContainer = styled.div`
  position: fixed;
  left: 0px;
  top: 60px;
  height: calc(100% - 60px);
  width: 180px;
  padding: 0 30px 0 30px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s ease-in-out;
  background-color: #ffffff;
  z-index: 2;

  @media (max-width: 800px) {
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    height: 100%;
    display: inherit;
    top: 0px;
    left: ${(props) => (props.show ? "0" : "-300px")};
    top: 0;
  }
`;

export const FilterSectionContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1500px) {
    :first-child {
      margin-top: 100px;
    }
  }
`;

export const FilterSectionHeader = styled.h4`
  color: #262626;
  font-size: 1.2em;
`;

export const FilterSectionLine = styled.div`
  margin-top: 2px;
  width: 25px;
  height: 1px;
  background-color: #262626;
`;

export const ControlsContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const FilterHamburger = styled.div`
  width: 48px;
  height: 48px;
  position: fixed;
  top: 15px;
  right: 80px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgb(0 0 0 / 50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  img {
    height: 35px;
  }

  :hover  {
    cursor: pointer;
  }
`;
