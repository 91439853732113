import React, { useRef, useState } from "react";

import { Button, ClickAwayListener, ListItemText, MenuItem, MenuList, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import { ThreeDots } from "react-loader-spinner";
import { SuccessMsg } from "../Career/careerStyled";
import { GetMatchingBusinesses } from "./getBusinessData";
import { LeftFormContainer, RightFormContainer, StyledFormContainer, StyledPhoneInput, StyledTextField } from "./styled";

const Form = () => {
  const timeoutRef = useRef(null);
  const recaptchaRef = React.useRef();
  const textFieldRef = useRef(null); // Create a ref

  const [successMsg, setSuccessMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [matchingBusinessesData, setMatchingBusinessesData] = useState([]);
  const [isPaperVisible, setIsPaperVisible] = useState(false);

  const handleOnClick = () => {
    ReactGA.event({
      category: `formulář - objednávka servisu`,
      action: `formulář - objednávka servisu`,
    });
  };

  const onSubmitWithReCAPTCHA = async () => {
    return await recaptchaRef.current.executeAsync();
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      ico: "",
      email: "",
      phone: "",
      address: "",
      brand: "",
      model: "",
      sn: "",
      message: "",
    },
    onSubmit: (values) => {
      if (onSubmitWithReCAPTCHA) {
        formik.values.phone = "+" + formik.values.phone;
        setLoader(true);

        axios.post(`${process.env.REACT_APP_URL}/sendEmail/serviceOrder`, values).then((response) => {
          if (response.status === 200) {
            formik.values.companyName = "";
            formik.values.ico = "";
            formik.values.email = "";
            formik.values.phone = "";
            formik.values.address = "";
            formik.values.brand = "";
            formik.values.model = "";
            formik.values.sn = "";
            formik.values.message = "";
            setSuccessMsg(true);
            setLoader(false);
            handleOnClick();
          } else {
            alert("Omlouváme se, ale při odesílání nastala chyba. Prosím, zkuste to znovu později");
            setLoader(false);
          }
        });
      }
    },
  });

  const onCompanyChange = (e) => {
    formik.handleChange(e);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (e.target.value === "") {
      setMatchingBusinessesData([]);
      setIsPaperVisible(false);
    }

    timeoutRef.current = setTimeout(() => {
      if (e.target.value !== "" && e.target.value !== undefined) {
        GetMatchingBusinesses(e.target.value, (data) => {
          setMatchingBusinessesData(data);
          setIsPaperVisible(data.length > 0);
        });
      }
    }, 500);
  };

  const onMenuItemClick = (business) => {
    formik.values.companyName = business.obchodniJmeno;
    formik.values.ico = business.ico;
    formik.values.address = business.sidlo.textovaAdresa;
    setIsPaperVisible(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledFormContainer>
        <LeftFormContainer>
          <div style={{ position: "relative" }}>
            <StyledTextField
              onChange={(e) => onCompanyChange(e)}
              onFocus={() => matchingBusinessesData?.length > 0 && setIsPaperVisible(true)}
              value={formik.values.companyName}
              name="companyName"
              type={"text"}
              variant="outlined"
              label="Jméno firmy / podnikatele"
              required
              autoComplete="off"
              fullWidth
              ref={textFieldRef}
            ></StyledTextField>

            {isPaperVisible && (
              <ClickAwayListener onClickAway={(e) => textFieldRef.current && !textFieldRef.current.contains(e.target) && setIsPaperVisible(false)}>
                <Paper style={{ position: "absolute", zIndex: 10, width: "100%" }}>
                  <MenuList style={{ width: "100%" }}>
                    {matchingBusinessesData?.map((business, index) => (
                      <MenuItem key={index} onClick={() => onMenuItemClick(business)}>
                        <ListItemText style={{ flexGrow: 1, maxWidth: "calc(100% - 5rem)" }}>
                          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}>{business.obchodniJmeno}</div>
                        </ListItemText>
                        <Typography style={{ marginLeft: "0.5rem" }} variant="caption" color="text.secondary">
                          {business.ico}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            )}
          </div>

          <StyledTextField value={formik.values.ico} onChange={formik.handleChange} name="ico" type={"number"} variant="outlined" label="IČO" required fullWidth></StyledTextField>
          <StyledTextField
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            style={{ marginTop: "15px" }}
            type={"email"}
            variant="outlined"
            label="E-mail"
            required
            fullWidth
          ></StyledTextField>
          <StyledPhoneInput
            value={formik.values.phone}
            onChange={(phone) => (formik.values.phone = phone)}
            country={"cz"}
            component={TextField}
            inputProps={{
              required: true,
              name: "phone",
              autoFocus: true,
            }}
          ></StyledPhoneInput>
        </LeftFormContainer>
        <RightFormContainer>
          <StyledTextField
            value={formik.values.address}
            onChange={formik.handleChange}
            name="address"
            type={"text"}
            variant="outlined"
            label="Adresa (kde stojí Váš stroj)"
            required
            fullWidth
          ></StyledTextField>
          <StyledTextField
            value={formik.values.brand}
            onChange={formik.handleChange}
            name="brand"
            style={{ marginTop: "15px" }}
            type={"text"}
            variant="outlined"
            label="Značka stroje"
            required
            fullWidth
          ></StyledTextField>
          <StyledTextField
            value={formik.values.model}
            onChange={formik.handleChange}
            name="model"
            style={{ marginTop: "15px" }}
            type={"text"}
            variant="outlined"
            label="Model stroje"
            required
            fullWidth
          ></StyledTextField>
          <StyledTextField
            value={formik.values.sn}
            onChange={formik.handleChange}
            name="sn"
            style={{ marginTop: "15px" }}
            type={"text"}
            variant="outlined"
            label="Výrobní číslo stroje"
            required
            fullWidth
          ></StyledTextField>
        </RightFormContainer>
      </StyledFormContainer>
      <StyledTextField
        value={formik.values.message}
        onChange={formik.handleChange}
        multiline
        rows={8}
        name="message"
        style={{ marginTop: "15px" }}
        type={"text"}
        variant="outlined"
        label="Popis závady stroje"
        required
        fullWidth
      ></StyledTextField>
      <div style={{ textAlign: "left" }}>
        <ReCAPTCHA hl="cs" ref={recaptchaRef} size="invisible" style={{ visibility: "hidden" }} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
      </div>
      {successMsg ? (
        <div style={{ textAlign: "left" }}>
          <SuccessMsg>Formulář byl úspešně odeslán!</SuccessMsg>
        </div>
      ) : null}
      <Button type="submit" variant="contained" style={{ backgroundColor: "#262626", marginTop: "15px", left: "50%", transform: "translate(-50%)" }} fullWidth>
        Odeslat
      </Button>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#262626"
        ariaLabel="three-dots-loading"
        wrapperStyle={{ position: "absolute", left: "50%", transform: "translatex(-40%)" }}
        visible={loader}
      />
    </form>
  );
};

export default Form;
