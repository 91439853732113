export const CareerData = [
  [
    // first
    {
      header: "Koho hledáme?",
      text: [
        "Sháníme instruktora manipulační techniky s technickým zaměřením a silnými komunikačními dovednostmi. Hledáme někoho, kdo má zkušenosti s vedením školení, je samostatný, pečlivý a má chuť se podílet na rozvoji naší firmy. Připojte se k nám a staňte se klíčovou součástí našeho týmu!",
      ],
      id: 0,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Školení řidičů manipulační techniky",
        "- Příprava a aktualizace školících materiálů",
        "- Teoretické a praktické školení řidičů ve svěřeném regionu ČR či školícím centru",
        "- Administrativa vystavení průkazu a jeho evidence v našem systému na PC",
        "- Komunikace se zákazníkem a jejich zpětná vazba k provedenému servisu",
        "- Prezentace výhody servisní smlouvy pro stávajícího zákazníka",
        "- Hledání nových obchodních případů",
      ],
      id: 0,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Stabilní zaměstnání v úspěšné rodinné firmě",
        "- Prvotřídní zázemí",
        "- Osobní vůz pro služební účely",
        "- Kvalitní a kompletní kurz instruktora (po roce praxe kurz zkušebního komisaře)",
        "- Telefon s neomezeným tarifem",
        "- Notebook",
        "- Podporu nadřízeného i kolegů",
        "- Zapracování společně se zkušeným kolegou",
        "- 20 dní dovolené + 3 dny osobního volna",
        "- Bonusové ohodnocení z obchodní činnosti formou měsíční prémie po zaučení",
      ],
      id: 0,
      sectionId: 2,
    },

    {
      header: "Co od Vás vyžadujeme?",
      text: [
        "- Výborné komunikační a prezentační schopnosti ",
        "- Elektrotechnické nebo strojírenské vzdělání výhodou",
        "- Aktivní přístup k práci, samostatnost, pečlivost",
        "- Čistý trestní rejstřík",
        "- Řidičský průkaz sk. B",
        "- Znalost práce na PC",
      ],
      id: 0,
      sectionId: 3,
    },
  ],
  [
    {
      header: "Koho hledáme?",
      text: [
        "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti středních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊 Vše potřebné vás naučíme. Zní to jako nabídka pro vás? Ozvěte se!",
      ],
      id: 0,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Oprava a údržba manipulační techniky a vysokozdvižných vozíků",
        "- Servisní práce v terénu přímo u zákazníka (oblast středních Čech)",
        "- Komunikace s nadřízeným, dispečery,...",
        "- Provádění diagnostiky závadyy, revize a další",
        "- Práce s PC (administrativa, řešení náhradních dílů…",
      ],
      id: 0,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Nástupní mzdu až 40 000 Kč dle zkušeností, po zaučení možnost navýšení",
        "- Uplatnění nejen pro uchazeče s praxí ale také pro absolventy 😊",
        "- Měsíční prémie",
        "- Spolupráci na HPP",
        "- Flexibilní pracovní dobu",
        "- Mobil, notebook",
        "- Servisní vůz pro pracovní účely",
        "- Kvalitní zaučení – nehodíme vás do vody",
        "- Možnost školení v zahraničí (Belgie, Německo)",
        "- Kvalitní firemní oděv",
        "- Zajímavou a různorodou práci",
        "- Prvotřídní zázemí včetně kvalitního nářadí",
        "- Firemní akce – firemní večeře, novoroční akce s programem…",
        "- Nástup dle domluvy",
        "- Práci v regionu středních Čech a okolí Mladé Boleslavi, Liberce, Nymburku…",
      ],
      id: 0,
      sectionId: 2,
    },

    {
      header: "Co od Vás vyžadujeme?",
      text: ["- Vzdělání technického směru (elektro, strojírenské…)", "- Nebo praxi na podobné pozici", "- Řidičský průkaz skupiny B", "- Příjemné vystupování a komunikační schopnosti"],
      id: 0,
      sectionId: 3,
    },
  ],
  [
    {
      header: "Koho hledáme?",
      text: [
        "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti západních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊 Vše potřebné vás naučíme. Zní to jako nabídka pro vás? Ozvěte se!",
      ],
      id: 1,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Oprava a údržba manipulační techniky a vysokozdvižných vozíků",
        "- Servisní práce v terénu přímo u zákazníka (oblast západních Čech)",
        "- Komunikace s nadřízeným, dispečery,...",
        "- Provádění diagnostiky závadyy, revize a další",
        "- Práce s PC (administrativa, řešení náhradních dílů…",
      ],
      id: 1,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Nástupní mzdu až 40 000 Kč dle zkušeností, po zaučení možnost navýšení",
        "- Uplatnění nejen pro uchazeče s praxí ale také pro absolventy 😊",
        "- Měsíční prémie",
        "- Spolupráci na HPP",
        "- Flexibilní pracovní dobu",
        "- Mobil, notebook",
        "- Servisní vůz pro pracovní účely",
        "- Kvalitní zaučení – nehodíme vás do vody",
        "- Možnost školení v zahraničí (Belgie, Německo)",
        "- Kvalitní firemní oděv",
        "- Zajímavou a různorodou práci",
        "- Prvotřídní zázemí včetně kvalitního nářadí",
        "- Firemní akce – firemní večeře, novoroční akce s programem…",
        "- Nástup dle domluvy",
      ],
      id: 1,
      sectionId: 2,
    },
    {
      header: "Co od Vás vyžadujeme?",
      text: ["- Vzdělání technického směru (elektro, strojírenské…)", "- Nebo praxi na podobné pozici", "- Řidičský průkaz skupiny B", "- Příjemné vystupování a komunikační schopnosti"],
      id: 1,
      sectionId: 3,
    },
  ],
  [
    {
      header: "Koho hledáme?",
      text: [
        "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti severních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊 Vše potřebné vás naučíme. Zní to jako nabídka pro vás? Ozvěte se!",
      ],
      id: 2,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Oprava a údržba manipulační techniky a vysokozdvižných vozíků",
        "- Servisní práce v terénu přímo u zákazníka (oblast severních Čech)",
        "- Komunikace s nadřízeným, dispečery,...",
        "- Provádění diagnostiky závadyy, revize a další",
        "- Práce s PC (administrativa, řešení náhradních dílů…",
      ],
      id: 2,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Nástupní mzdu až 40 000 Kč dle zkušeností, po zaučení možnost navýšení",
        "- Uplatnění nejen pro uchazeče s praxí ale také pro absolventy 😊",
        "- Měsíční prémie",
        "- Spolupráci na HPP",
        "- Flexibilní pracovní dobu",
        "- Mobil, notebook",
        "- Servisní vůz pro pracovní účely",
        "- Kvalitní zaučení – nehodíme vás do vody",
        "- Možnost školení v zahraničí (Belgie, Německo)",
        "- Kvalitní firemní oděv",
        "- Zajímavou a různorodou práci",
        "- Prvotřídní zázemí včetně kvalitního nářadí",
        "- Firemní akce – firemní večeře, novoroční akce s programem…",
        "- Nástup dle domluvy",
      ],
      id: 2,
      sectionId: 2,
    },
    {
      header: "Co od Vás vyžadujeme?",
      text: ["- Vzdělání technického směru (elektro, strojírenské…)", "- Nebo praxi na podobné pozici", "- Řidičský průkaz skupiny B", "- Příjemné vystupování a komunikační schopnosti"],
      id: 2,
      sectionId: 3,
    },
  ],
  [
    {
      header: "Koho hledáme?",
      text: [
        "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti východních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊 Vše potřebné vás naučíme. Zní to jako nabídka pro vás? Ozvěte se!",
      ],
      id: 3,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Oprava a údržba manipulační techniky a vysokozdvižných vozíků",
        "- Servisní práce v terénu přímo u zákazníka (oblast východních Čech)",
        "- Komunikace s nadřízeným, dispečery",
        "- Provádění diagnostiky závadyy, revize a další",
        "- Práce s PC (administrativa, řešení náhradních dílů…)",
      ],
      id: 3,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Nástupní mzdu až 40 000 Kč dle zkušeností, po zaučení možnost navýšení",
        "- Uplatnění nejen pro uchazeče s praxí ale také pro absolventy 😊",
        "- Měsíční prémie",
        "- Spolupráci na HPP",
        "- Flexibilní pracovní dobu",
        "- Mobil, notebook",
        "- Servisní vůz pro pracovní účely",
        "- Kvalitní zaučení – nehodíme vás do vody",
        "- Možnost školení v zahraničí (Belgie, Německo)",
        "- Kvalitní firemní oděv",
        "- Zajímavou a různorodou práci",
        "- Prvotřídní zázemí včetně kvalitního nářadí",
        "- Firemní akce – firemní večeře, novoroční akce s programem…",
        "- Nástup dle domluvy",
      ],
      id: 3,
      sectionId: 2,
    },
    {
      header: "Co od Vás vyžadujeme?",
      text: ["- Vzdělání technického směru (elektro, strojírenské…)", "- Nebo praxi na podobné pozici", "- Řidičský průkaz skupiny B", "- Příjemné vystupování a komunikační schopnosti"],
      id: 3,
      sectionId: 3,
    },
  ],
  [
    {
      header: "Koho hledáme?",
      text: [
        "Hledáme kolegu s technickým vzděláním nebo praxí pro práci na dílenském provozu. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊 Vše potřebné vás naučíme. Zní to jako nabídka pro vás? Ozvěte se!",
      ],
      id: 4,
      sectionId: 0,
    },
    {
      header: "Jakou budete mít náplň práce?",
      text: [
        "- Oprava a údržba manipulační techniky a vysokozdvižných vozíků",
        "- Servisní práce na dílenském provozu v místě Králův Dvůr",
        "- Komunikace s kolegy a dispečery,...",
        "- Provádění diagnostiky závady, revize a další",
        "- Práce s PC (administrativa, řešení náhradních dílů…",
      ],
      id: 4,
      sectionId: 1,
    },
    {
      header: "Co Vám nabízíme?",
      text: [
        "- Nástupní mzdu až 40 000 Kč dle zkušeností, po zaučení možnost navýšení",
        "- Uplatnění nejen pro uchazeče s praxí ale také pro absolventy 😊",
        "- Měsíční prémie",
        "- Spolupráci na HPP",
        "- Flexibilní pracovní dobu",
        "- Mobil",
        "- Kvalitní zaučení – nehodíme vás do vody",
        "- Kvalitní firemní oděv",
        "- Zajímavou a různorodou práci",
        "- Prvotřídní zázemí včetně kvalitního nářadí",
        "- Firemní akce – firemní večeře, novoroční akce s programem…",
        "- Nástup dle domluvy",
      ],
      id: 4,
      sectionId: 2,
    },
    {
      header: "Co od Vás vyžadujeme?",
      text: ["- Vzdělání technického směru (elektro, strojírenské…)", "- Nebo praxi na podobné pozici výhodou"],
      id: 4,
      sectionId: 3,
    },
  ],
];
