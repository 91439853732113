import styled from "styled-components";

import fontSize from "../../global/fontSize";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 600px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
`;

export const Header = styled.h2`
  font-size: ${fontSize.mainHeader};
  color: white;

  @media (max-width: 1000px) {
    font-size: 48px;
  }
`;

export const ArrowDown = styled.img`
  position: absolute;
  height: 70px;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s;
  z-index: 2;

  :hover {
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.6;
  }

  @media (max-width: 1250px) {
    bottom: 50px;
  }

  @media (max-width: 500px) {
    height: 70px;
  }
`;
