import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
`;

export const StyledForm = styled.form`
  width: 100%;
  margin-top: 8rem;
  padding: 0 20px;

  @media (min-width: 800px) {
    width: 500px;
  }
`;

export const FileInputLabel = styled.p`
  margin-top: 15px;
`;

export const FileInputImage = styled.img`
  height: 80px;
  margin-top: 10px;
  margin-right: 10px;
`;

export const StyledLabel = styled.label`
  margin-bottom: 5px;
`;

export const StyledSelect = styled.select`
  display: block;
  height: 60px;
  width: 100%;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 1rem;
  padding: 16.5px 14px;
  margin-bottom: 15px;
`;
