import React from "react";
import Footer from "../../components/Footer";
import TopBanner from "../../components/TopBanner";

import Card from "../../components/Card";
import { CardsContainer, Container, ContentContainer } from "./styled";

import BannerImg from "../../images/OurServices/BannerImg.jpg";

import { CardData } from "../../data/cardData";
import RPKOfiicialLogo from "../../images/rpktechnik_original_logo.png";
import GoogleAnalytics from "../../utils/GoogleAnalytics";

const Career = () => {
  GoogleAnalytics();

  return (
    <>
      <Container>
        <TopBanner img={BannerImg} header="Jaké služby nabízíme?" />
        <ContentContainer>
          <img src={RPKOfiicialLogo} alt="Logo rpktechnik" style={{ visibility: "hidden", height: 0, width: 0 }} />
          <CardsContainer>
            {CardData.map((props) => {
              return <Card src={props.img} alt={props.alt} header={props.header} data={props.header} text={props.text} buttonText={props.buttonText} url="/Contact"></Card>;
            })}
          </CardsContainer>
        </ContentContainer>
      </Container>
      <Footer />
    </>
  );
};

export default Career;
