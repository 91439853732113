import { React } from "react";
import { Header } from "./styled";

import colors from "../../global/colors";

const MainHeader = (props) => {
  let headerColor;
  if (props.headerColor === "black") {
    headerColor = colors.black;
  } else {
    headerColor = "#fff";
  }
  return <Header style={{ color: headerColor }}>{props.text}</Header>;
};
export default MainHeader;
