import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const MachinesContainer = styled.div`
  position: relative;
  margin: 0 auto;
  margin-left: 240px;
  width: calc(100% - 240px);
  min-height: 100vh;

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 160px;
  text-align: center;

  @media (max-width: 1410px) {
    width: unset;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Header = styled.h3`
  font-size: 40px;
  color: #262626;
  display: inline-block;

  @media (max-width: 700px) {
    font-size: 32px;
    width: max-content;
  }
`;

export const HeaderStyleLine = styled.span`
  width: 120px;
  height: 2.5px;
  background-color: #262626;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CardsContainer = styled.div`
  position: relative;
  padding-top: 4rem;
  margin: 0 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-bottom: 10rem;

  @media (max-width: 1410px) {
    justify-content: center;
  }

  @media (max-width: 700px) {
    padding-top: 2rem;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 310px;
  min-height: 560px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  transition: 0.2s;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 220px;
  background-size: 100%;
  background-position: center;
  border-radius: 20px;
  border: 0.5px solid #c5c5c5;
`;

export const CardHeader = styled.h4`
  font-size: 28px;
  margin: 1.3rem 0;

  @media (max-width: 700px) {
    font-size: 24px;
  }
`;

export const PropertiesContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 120px;
  left: 50%;
  transform: translateX(-45%);
  gap: 15px 0;
`;

export const PropertyContainerLeft = styled.div`
  height: 30%;
  width: 51%;
  display: flex;
`;

export const PropertyContainerRight = styled.div`
  height: 30%;
  margin-left: 10px;
  display: flex;
`;

export const PropertyTextContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: 10px;
  padding-top: 3px;
`;

export const PropertyHeader = styled.h4`
  font-size: 12px;
  color: #757575;
  font-weight: 500;
`;

export const PropertyText = styled.p`
  color: #262626;
  font-size: 16px;
`;

export const PriceContainer = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  margin-top: 3rem;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
`;

export const PriceTextContainer = styled.div`
  text-align: left;
`;

export const PriceText = styled.h4`
  color: #262626;
  font-size: 20px;
`;
