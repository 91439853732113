import React from "react";
import Footer from "../../components/Footer";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import AboutUsPage from "./AboutUs";
import LandingPage from "./Landing";
import OurServices from "./OurServices";
import References from "./References";

const Home = () => {
  GoogleAnalytics();
  return (
    <>
      <LandingPage />
      <AboutUsPage />
      <OurServices />
      <References />
      <Footer />
    </>
  );
};

export default Home;
