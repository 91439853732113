import React, { useState } from "react";

import { TextField, Button } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import ReactGA from "react-ga4";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { SuccessMsg } from "./careerStyled";
import { ThreeDots } from "react-loader-spinner";

import { useSearchParams } from "react-router-dom";

const Form = () => {
  const [fileName, setFileName] = useState("");
  const recaptchaRef = React.useRef();
  const [successMsg, setSuccessMsg] = useState(false);
  const [loader, setLoader] = useState(false);

  const [searchparams] = useSearchParams();
  const mainHeader = searchparams.get("header");

  const handleOnClick = () => {
    ReactGA.event({
      category: `formulář - kariéra`,
      action: `formulář - kariéra`,
    });
  };

  const onSubmitWithReCAPTCHA = async () => {
    return await recaptchaRef.current.executeAsync();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      file: "",
    },
    onSubmit: (values) => {
      if (onSubmitWithReCAPTCHA) {
        if (fileName === "") {
          alert("Prosím, nahrajte svůj životopis!");
          return;
        } else {
          formik.values.phone = "+" + formik.values.phone;
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("phone", values.phone);
          formData.append("message", values.message);
          formData.append("careerName", mainHeader);
          formData.append("file", values.file);
          setLoader(true);

          axios.post(`${process.env.REACT_APP_URL}/sendEmail/career`, formData).then((response) => {
            if (response.status === 200) {
              formik.values.name = "";
              formik.values.email = "";
              formik.values.phone = "";
              formik.values.message = "";
              formik.values.file = "";
              setSuccessMsg(true);
              setLoader(false);
              handleOnClick();
            } else {
              alert("Omlouváme se, ale při odesílání nastala chyba. Prosím, zkuste to znovu později");
              setLoader(false);
            }
          });
        }
      }
    },
  });

  return (
    <>
      <form style={{ position: "relative" }} onSubmit={formik.handleSubmit}>
        <TextField onChange={formik.handleChange} value={formik.values.name} name="name" type={"text"} variant="outlined" label="Jméno a příjmení" required fullWidth></TextField>
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          name="email"
          style={{ marginTop: "15px" }}
          type={"email"}
          variant="outlined"
          label="E-mail"
          required
          fullWidth
        ></TextField>
        <PhoneInput
          value={formik.values.phone}
          onChange={(phone) => (formik.values.phone = phone)}
          country={"cz"}
          style={{ marginTop: "15px" }}
          component={TextField}
          inputProps={{
            required: true,
            name: "phone",
            autoFocus: true,
          }}
        ></PhoneInput>
        <TextField
          value={formik.values.message}
          onChange={formik.handleChange}
          multiline
          rows={8}
          name="message"
          style={{ marginTop: "15px" }}
          type={"text"}
          variant="outlined"
          label="Proč bychom Vás měli chtít?"
          required
          fullWidth
        ></TextField>
        <div style={{ textAlign: "left" }}>
          <ReCAPTCHA hl="cs" ref={recaptchaRef} size="invisible" style={{ visibility: "hidden" }} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} />
        </div>
        <div style={{ textAlign: "left" }}>
          {fileName === "" && <p style={{ marginTop: "30px", color: "#262626" }}>Prosím, nahrajte svůj životopis</p>}
          {fileName !== "" && <p style={{ marginTop: "30px", color: "#262626" }}>{fileName}</p>}

          <Button variant="outlined" component="label" style={{ width: "100%", marginTop: "15px" }}>
            Nahrát životopis
            <input
              type="file"
              name="file"
              onChange={(e) => {
                formik.values.file = e.currentTarget.files[0];
                if (formik.values.file) {
                  setFileName(formik.values.file.name);
                } else {
                  setFileName("");
                }
              }}
              accept="application/pdf, .doc, .docx"
              hidden
            />
          </Button>
        </div>
        <Button type="submit" variant="contained" style={{ width: "100%", backgroundColor: "#262626", marginTop: "15px", left: "50%", transform: "translate(-50%)", transition: "0.2s" }}>
          Odeslat
        </Button>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#262626"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ position: "absolute", left: "50%", transform: "translatex(-50%)" }}
          wrapperClassName=""
          visible={loader}
        />
        {successMsg ? (
          <div style={{ textAlign: "left" }}>
            <SuccessMsg>Formulář byl úspešně odeslán!</SuccessMsg>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default Form;
