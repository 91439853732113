import { Button } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0 50px 0;
`;

export const Header = styled.h4`
  padding: 50px 0 25px 0;
  font-size: 20px;
  transition: height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const Text = styled.p`
  transition: height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  padding: 0 400px 25px 400px;

  @media (max-width: 1500px) {
    padding: 0 200px 25px 200px;
  }

  @media (max-width: 900px) {
    padding: 0 50px 25px 50px;
  }
`;

export const DeclineButton = styled(Button)`
  margin-right: 25px !important;
  width: 120px !important;
`;

export const AcceptButton = styled(Button)`
  width: 120px !important;
`;
