import styled from "styled-components";
import fontSize from "../../global/fontSize";

export const Header = styled.h2`
  font-weight: 800;
  font-size: ${fontSize.secondMainHeader};
  margin-bottom: 30px;
  padding: 0 20px;

  @media (max-width: 1250px) {
    font-size: ${fontSize.smallHeader};
  }

  @media (max-width: 750px) {
    font-size: ${fontSize.mostSmallHeader};
  }

  @media (max-width: 500px) {
    font-size: 32px;
    margin-bottom: 0;
  }
`;
