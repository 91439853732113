// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mainHeader: 90 + "px",
  secondMainHeader: 84 + "px",
  smallHeader: 64 + "px",
  mostSmallHeader: 48 + "px",
  biggerText: 28 + "px",
  text24: 24 + "px",
  text: 20 + "px",
  smallerText: 12 + "px",
};
