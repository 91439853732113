import styled from "styled-components";

import bcgImg from "../../images/Contact/backgroundImg.png";

export const Container = styled.div`
  width: 100vw;
  height: 85vh;
  position: relative;
  background-image: url(${bcgImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 750px;

  @media (max-width: 1500px) {
    min-height: 100vh;
    height: auto;
  }
`;

export const ContentContainer = styled.div`
  width: 80vw;
  height: 95vh;
  margin: 0 auto;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 750px;

  @media (max-width: 1500px) {
    min-height: 100vh;
    height: auto;
    width: 40vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
  }

  @media (max-width: 1300px) {
    width: 50vw;
  }

  @media (max-width: 900px) {
    width: 70vw;
  }

  @media (max-width: 600px) {
    width: 90vw;
  }

  @media (max-height: 900px) {
    padding-top: 5rem;
  }
`;

export const Header = styled.h2`
  font-family: "Oswald", sans-serif;
  font-size: 90px;
  color: #1b292a;
  margin-bottom: 7rem;

  @media (max-width: 1500px) {
    display: none;
  }

  @media (max-height: 900px) and (min-width: 1500px) {
    margin-bottom: 7rem;
  }

  @media (max-width: 1500px) {
    font-size: 50px;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    font-size: 40px;
  }
`;

export const CardContainer = styled.div`
  padding: 40px 0;
  width: 30vw;
  min-width: 450px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  min-height: 800px;
  z-index: 2;

  @media (max-height: 900px) and (min-width: 1500px) {
    margin-bottom: 8rem;
  }

  @media (max-width: 1500px) {
    padding: 15px 0;
    width: 40vw;
  }

  @media (max-height: 1000px) {
    min-height: 700px;
    margin-bottom: 2rem;
  }
  @media (max-width: 1300px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    min-width: unset;
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  height: 100%;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-height: 1000px) {
    height: 100%;
  }
`;

export const FormHeader = styled.h4`
  font-size: 16px;
  margin-bottom: 15px;
`;

//footer
export const FooterContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 33vh;
  background-color: #262626;
  color: white;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  @media (max-width: 1350px) {
    height: auto;
  }
`;

export const FooterContentContainer = styled.div`
  width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1350px) {
    flex-direction: column;
    margin: 2rem auto;
  }

  @media (max-width: 610px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  min-width: 350px;

  @media (max-width: 1350px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    align-items: flex-start;
    margin-top: 2rem;
  }
  @media (max-width: 610px) {
    margin-top: 0;
    width: 100%;
    align-items: center;
  }
`;

export const LogoImg = styled.img``;

export const IcoElem = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-top: 30px;
  font-weight: 500;

  @media (max-width: 610px) {
    font-size: 14px;
  }
`;

export const ContactsContainer = styled.div`
  position: relative;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10vw;

  @media (max-width: 1350px) {
    flex-direction: row;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @media (max-width: 610px) {
    margin-left: 2rem;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const ContactImg = styled.img`
  margin-right: 10px;

  @media (max-width: 610px) {
    width: 20px;
  }
`;

export const ContactLink = styled.a`
  margin-right: 50px;
  color: white;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    opacity: 75%;
    transition: 0.2s;
  }

  @media (max-width: 1250px) {
    margin-right: 20px;
  }

  @media (max-width: 610px) {
    font-size: 14px;
  }
`;

export const FooterBottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #202020;
  font-size: 12px;
  text-align: center;

  p {
    padding: 5px 50px 5px 50px;
  }
`;
