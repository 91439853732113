import useSWRInfinite from "swr/infinite";

export async function Fetcher(url, token) {
  let path = await url;

  //`https://sidaps-heroku-first-test.herokuapp.com${path}`
  const req = await fetch(`${process.env.REACT_APP_URL}${path}`, {
    headers: {
      Authorization: `token ${token}`,
    },
  });

  if (req.status === 403) {
    EmergencyLogOut();
  }

  if (req.status !== 200) {
    alert("Nastala chyba. Prosím, zkuste to znovu později");
  }

  return await req.json();
}

export function EmergencyLogOut() {
  localStorage.removeItem("session");
}

function getKey(path, options, pageIndex, previousPageData) {
  if (previousPageData && !previousPageData.length) return null;

  return `${path}/type1:${options.type1}/type2:${options.type2}/type3:${options.type3}/drive1:${options.drive1}/drive2:${options.drive2}/drive3:${options.drive3}/yearFrom:${options.yearFrom}/yearTo:${
    options.yearTo
  }/order:${options.order}/salePriceFrom:${options.salePriceFrom}/salePriceTo:${options.salePriceTo}/offset:${pageIndex * options.limit}/limit:${options.limit}`;
}

export function useLazyLoading(path, options) {
  const { data, size, setSize } = useSWRInfinite((pageIndex, previousPageData) => getKey(path, options, pageIndex, previousPageData), {
    initialSize: 1,
    revalidateAll: true,
  });

  const isReachedEnd = data && data[data.length - 1]?.length < options.limit; //got last batch of data
  return { data, setSize, size, isReachedEnd };
}
