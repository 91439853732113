import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100vw;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const ButtonsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
