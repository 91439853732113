import React from "react";
import { Image, NoMachinesContainer, NoMachinesHeader, NoMachinesLabel } from "./styled";
import iSadSmiley from "../../images/sadSmiley.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function NoMachines() {
  const navigate = useNavigate();
  return (
    <NoMachinesContainer>
      <Image src={iSadSmiley} alt="Smutný smajlík" />
      <NoMachinesHeader>Bohužel jsme žádné stroje nenašli</NoMachinesHeader>
      <NoMachinesLabel>
        Bohužel jsme v aktuální nabídce manipulační techniky nic nenašli. Jestliže hledáte konkrétní stroj nebo typ manipulační techniky, kontaktujte nás a podíváme se po vhodném stroji.
      </NoMachinesLabel>
      <Button variant="contained" fullWidth style={{ backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }} onClick={() => navigate("/contact")}>
        Kontakt
      </Button>
    </NoMachinesContainer>
  );
}

export default NoMachines;
