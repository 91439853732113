import { useNavigate } from "react-router-dom";

export function GetSession() {
  return localStorage.getItem("session");
}

export const CheckSession = async () => {
  const navigate = useNavigate();

  if (GetSession() !== null) {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/auth/check-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "token " + GetSession() },
      });

      if (res.status !== 200) {
        navigate("/");
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else {
    window.location.replace("/");
    return false;
  }
};
