import { Button } from "@mui/material";
import React from "react";
import { BsSignpost2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CheckSession } from "../../utils/CheckSession";
import { ButtonsContainer, ButtonsGroupContainer } from "./styled";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Administration = () => {
  const navigate = useNavigate();

  return (
    CheckSession() && (
      <Container>
        <BsSignpost2 style={{ fontSize: "8rem", marginBottom: "100px", color: "#262626" }} />
        <ButtonsContainer>
          <ButtonsGroupContainer>
            <Button
              onClick={() => navigate("/administration/machines")}
              type="button"
              variant="contained"
              style={{ width: "200px", marginRight: "10px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}
            >
              Pronájem a prodej
            </Button>

            <Button
              onClick={() => navigate("/administration/machines/deleted-machines")}
              type="button"
              variant="contained"
              style={{ width: "200px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}
            >
              Koš
            </Button>
          </ButtonsGroupContainer>

          <ButtonsGroupContainer>
            <Button type="button" variant="contained" style={{ width: "200px", marginRight: "10px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}>
              Kariéra
            </Button>

            <Button
              onClick={() => navigate("/administration/machines")}
              type="button"
              variant="contained"
              style={{ width: "200px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}
            >
              Koš
            </Button>
          </ButtonsGroupContainer>

          <ButtonsGroupContainer>
            <Button type="button" variant="contained" style={{ width: "200px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}>
              Trakční baterie
            </Button>

            <Button
              onClick={() => navigate("/administration/machines")}
              type="button"
              variant="contained"
              style={{ width: "200px", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}
            >
              Koš
            </Button>
          </ButtonsGroupContainer>
        </ButtonsContainer>
      </Container>
    )
  );
};

export default Administration;
