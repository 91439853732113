import styled from "styled-components";

import bcgImg from "../../../images/Home/OurServices/background.jpg";
import fontSize from "../../../global/fontSize";
import colors from "../../../global/colors";

export const OurServicesContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(${colors.backgroundLinearGrey}, #fff);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1000px) and (max-height: 1500px) {
    height: auto;
    min-height: 100vh;
    margin-bottom: 8rem;
  }

  @media (max-width: 1500px) {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }
`;

export const ContentContainer = styled.div`
  width: 90%;
  height: 90%;
`;

export const MainInformationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
  height: 80%;
  background: linear-gradient(rgba(32, 36, 36, 0.75), rgba(32, 36, 36, 0.75)), url(${bcgImg});
  background-size: cover;
  text-align: center;

  @media (min-width: 900px) and (max-height: 1500px) {
    height: 800px;
    top: 100px;
  }

  @media (max-width: 1500px) {
    width: 100%;
    height: 600px;
    margin: 5rem 0;
  }

  @media (max-width: 500px) {
    height: 400px;
  }
`;

export const Triangle = styled.div`
  width: 60px;
  height: 60px;
  clip-path: polygon(100% 0, 30% 50%, 100% 100%);
  background-color: #f4f4f4;
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1500px) {
    left: 50%;
    top: 90.1%;
    transform: translateX(-50%) rotate(90deg);
    background-color: #f1f1f1;
  }

  @media (max-width: 1000px) {
    background-color: #ececec;
  }

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;

export const Header = styled.h3`
  font-size: ${fontSize.smallHeader};
  color: white;

  @media (max-width: 1750px) {
    font-size: ${fontSize.mostSmallHeader};
  }

  @media (max-width: 500px) {
    font-size: 32px;
  }
`;

export const Text = styled.p`
  margin-top: 40px;
  margin-bottom: 40px;
  color: white;
  font-size: ${fontSize.text};
  font-weight: 300;
  padding: 0 60px;

  @media (max-width: 1750px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const CardsContainer = styled.div`
  position: relative;
  width: 60%;
  height: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 900px) and (max-height: 1500px) {
    height: 800px;
    top: 120px;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }
`;
