import styled from "styled-components";
import backgroundImgFHD from "../../../images/Home/LandingPage/background.jpg";
import fontSize from "../../../global/fontSize";

export const LandingContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25)), url(${backgroundImgFHD}) no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  color: white;
  overflow-x: hidden;
`;

export const HeaderBackground = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 45% 100%, 0% 100%);
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1250px) {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 1;
  }

  @media (max-height: 750px) {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 1;
  }
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65%;
  text-align: center;

  @media (max-width: 1250px) {
    position: absolute;
    width: 100%;
  }

  @media (max-height: 750px) {
    position: absolute;
    width: 100%;
  }
`;

export const LandingHeader = styled.h1`
  font-family: "League Gothic", sans-serif;
  font-size: ${fontSize.mainHeader};
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 50px;
  position: relative;

  @media (max-width: 720px) {
    font-size: ${fontSize.smallHeader};
  }

  @media (max-width: 499px) {
    font-size: ${fontSize.mostSmallHeader};
  }
`;

export const ForkliftImg = styled.img`
  position: absolute;
  width: 1280px;
  bottom: 5%;
  right: -300px;

  @media (max-width: 1400px) {
    width: 1150px;
    bottom: 10%;
  }

  @media (max-width: 1250px) {
    display: none;
  }
  @media (max-height: 900px) {
    width: 1050px;
  }
  @media (max-height: 750px) {
    display: none;
  }
`;

export const ArrowDown = styled.img`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s;
  z-index: 2;

  :hover {
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.6;
  }

  @media (max-width: 1250px) {
    bottom: 50px;
  }

  @media (max-width: 500px) {
    height: 70px;
  }
`;
