import React from "react";
import MainHeader from "../../../components/MainHeader";
import {
  Card,
  CardBottomContainer,
  CardBottomImgContainer,
  CardBottomImg,
  CardHeader,
  CardsContainer,
  CardText,
  HeaderContainer,
  ReferencesContainer,
  SliderTextContainer,
  CardBottomIconContainer,
} from "./styled";

import SmartSlider from "react-smart-slider";
import FirstCardBackground from "../../../images/Home/References/1stCardBackground2.jpg";
import SecondCardBackground from "../../../images/Home/References/references.jpg";
import ThirdCardBackground from "../../../images/Home/References/profesionality.jpg";
import iBadge from "../../../images/Home/References/worker.svg";
import StillImg from "../../../images/Home/References/Still.svg";
import ToyotaImg from "../../../images/Home/References/Toyota.svg";
import CesabImg from "../../../images/Home/References/Cesab.svg";
import LindeImg from "../../../images/Home/References/Linde.svg";
import JungheinrichImg from "../../../images/Home/References/Jungheinrich.svg";
import BtImg from "../../../images/Home/References/Bt.svg";
import { motion } from "framer-motion";

function References() {
  let slidesArrays = [
    {
      childrenElem: (
        <SliderTextContainer>
          <h5>Tomáš Kukla z firmy Eurofrost</h5>
          <p>S provedenou servisní službou jsem byl spokojen, a to bez výhrad. Celý proces proběhl podle představ, závada byla na vozíku úspěšně odstraněna</p>
        </SliderTextContainer>
      ),
    },
    {
      childrenElem: (
        <SliderTextContainer>
          <h5>Jaroslav Kollmann z firmy Sanpak</h5>
          <p>S provedenou servisní službou jsem byl velice spokojen. Celý proces proběhl podle představ, ostatně jako vždy</p>
        </SliderTextContainer>
      ),
    },
  ];

  return (
    <ReferencesContainer>
      <HeaderContainer>
        <MainHeader headerColor="black" text="Proč využít naše služby?"></MainHeader>
      </HeaderContainer>
      <CardsContainer>
        <Card>
          <CardHeader>Pracujeme s mnoha značkami v kvalitách autorizovaného servisu</CardHeader>
          <CardText style={{ marginTop: "5px" }}>
            Už Vás unavuje volat pro každou značku vozíku jinou firmu? Pracujeme s mnoha značkami VZV: <strong>STILL, Toyota, BT, Linde, Jungheinrich a Cesab.</strong> Kontaktujte nás.
          </CardText>
          <CardBottomContainer style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${FirstCardBackground})`, backgroundSize: "cover" }}>
            <CardBottomImgContainer></CardBottomImgContainer>
          </CardBottomContainer>
        </Card>
        <Card>
          <CardHeader style={{ marginTop: "10px" }}>Máme skvělé reference od zákazníků</CardHeader>
          <CardText>Díky naší zkušenosti zajišťujeme kvalitní služby, a díky tomu máme spoustu spokojených zákazníků, kteří nás doporučují i jiným firmám. Staňte se jedním z nich!</CardText>
          <CardBottomContainer style={{ background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${SecondCardBackground})`, backgroundSize: "cover" }}>
            <SmartSlider slides={slidesArrays} showIndicator={false} autoSlide="true" autoSlideInterval={15000} />
          </CardBottomContainer>
        </Card>
        <Card>
          <CardHeader style={{ marginTop: "20px" }}>Profesionální přístup</CardHeader>
          <CardText>Nabízíme opravy a údržby vysokozdvižných vozíků přímo u vás na místě. Servis provádí profesionálně vyškolení servisní technici, vybavení pojiznými dílnami.</CardText>
          <CardBottomContainer style={{ background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${ThirdCardBackground})`, backgroundSize: "cover" }}>
            <CardBottomIconContainer>
              {/* <img src={iBadge} alt="Obrázek odznaku" />
              <p>Opravujeme Vám vozíky už přes 10 let</p> */}
            </CardBottomIconContainer>
          </CardBottomContainer>
        </Card>
      </CardsContainer>
    </ReferencesContainer>
  );
}

//as={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: false }} ON CARD

export default References;

// <CardBottomContainer style={{ background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${FirstCardBackground})`, backgroundSize: "cover" }}>
// <CardBottomImgContainer>
//   <CardBottomImg src={StillImg} alt="Logo firmy Still"></CardBottomImg>
//   <CardBottomImg src={ToyotaImg} alt="Logo firmy Toyota"></CardBottomImg>
//   <CardBottomImg src={CesabImg} alt="Logo firmy Cesab" style={{ height: "25px" }}></CardBottomImg>
//   <CardBottomImg src={LindeImg} alt="Logo firmy Linde" style={{ marginLeft: "0" }}></CardBottomImg>
//   <CardBottomImg src={JungheinrichImg} alt="Logo firmy Jungheinrich"></CardBottomImg>
//   <CardBottomImg src={BtImg} alt="Logo firmy Toyota BT"></CardBottomImg>
// </CardBottomImgContainer>
// </CardBottomContainer>
