import React from "react";
import { motion } from "framer-motion";

import { Card, CardContainer, CardHeader, CardMapContainer, CardMapImg, CardMapText, CardText, CardTextContainer, Container, Header, HeaderContainer, HeaderStyleLine } from "./styled";

import Footer from "../../components/Footer";

import { createSearchParams, useNavigate } from "react-router-dom";

import { CareerOverviewData } from "../../data/careerOverviewData";
import TopBanner from "../../components/TopBanner";

import bannerBackgroundImg from "../../images/Career/backgroundImg.jpg";
import mapImg from "../../images/Career/map.svg";
import GoogleAnalytics from "../../utils/GoogleAnalytics";

const CareerOverview = () => {
  GoogleAnalytics();

  const navigate = useNavigate();

  const openCareer = (id, header) => {
    navigate({ pathname: "/career-position", search: createSearchParams({ id: id, header: header }).toString() });
  };

  return (
    <>
      <TopBanner img={bannerBackgroundImg} header="Hledáme talentované lidi!" />
      <Container>
        <HeaderContainer>
          <Header>Přehled volných pozic</Header>
          <HeaderStyleLine />
        </HeaderContainer>
        <CardContainer>
          {CareerOverviewData.map((props) => {
            return (
              <Card as={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: false }} key={props.id} onClick={() => openCareer(props.id, props.header)}>
                <CardHeader>{props.header}</CardHeader>
                <CardTextContainer>
                  <CardText>{props.text}</CardText>
                </CardTextContainer>
                <CardMapContainer>
                  <CardMapImg src={mapImg} alt="Ikona znázorňující mapu" />
                  <CardMapText>{props.map}</CardMapText>
                </CardMapContainer>
              </Card>
            );
          })}
        </CardContainer>
      </Container>
      <Footer />
    </>
  );
};

export default CareerOverview;
