export const CareerOverviewData = [
  // {
  //   header: "Interní školitel řidičů VZV",
  //   text: "Sháníme instruktora manipulační techniky s technickým zaměřením a silnými komunikačními dovednostmi. Hledáme někoho, kdo má zkušenosti s vedením školení, je samostatný, pečlivý a má chuť se podílet na rozvoji naší firmy. Připojte se k nám a staňte se klíčovou součástí našeho týmu!",
  //   map: "Čechy",
  //   id: 0,
  // },
  {
    header: "Servisní technik v terénu",
    text: "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti středních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊    ",
    map: "Střední Čechy",
    id: 0,
  },
  // {
  //   header: "Servisní technik v terénu",
  //   text: "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti západních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊    ",
  //   map: "Západní Čechy",
  //   id: 2,
  // },
  // {
  //   header: "Servisní technik v terénu",
  //   text: "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti severních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊    ",
  //   map: "Severní Čechy",
  //   id: 3,
  // },
  // {
  //   header: "Servisní technik v terénu",
  //   text: "Hledáme kolegu s technickým vzděláním nebo praxí, který tu bude pro naše zákazníky v oblasti východních Čech. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊    ",
  //   map: "Východní Čechy",
  //   id: 4,
  // },
  // {
  //   header: "Servisní technik na dílně",
  //   text: "Hledáme kolegu s technickým vzděláním nebo praxí pro práci na dílenském provozu. Můžete být zkušený technik anebo absolvent, který cítí, že by to byla práce přesně pro něj. 😊    ",
  //   map: "Východní Čechy",
  //   id: 5,
  // },
];
