import React from "react";
import TopBanner from "../../components/TopBanner";
import Footer from "../../components/Footer";

import { Container, FormContainer, FormHeader } from "./styled";

import BannerImg from "../../images/ServiceOrder/BannerImg.jpg";
import Form from "./form";
import GoogleAnalytics from "../../utils/GoogleAnalytics";

const ServiceOrder = () => {
  GoogleAnalytics();

  return (
    <>
      <Container>
        <TopBanner img={BannerImg} header="Objednejte si servis!" />
        <FormContainer>
          <FormHeader>Objednávka servisu</FormHeader>
          <Form />
        </FormContainer>
      </Container>
      <Footer />
    </>
  );
};

export default ServiceOrder;
