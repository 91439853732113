import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SmartSlider from "react-smart-slider";
import Footer from "../../components/Footer";
import {
  Container,
  DataContainer,
  Header,
  HeaderContainer,
  HeaderStyleLine,
  MainDataContainer,
  PriceContainer,
  PriceText,
  PriceTextContainer,
  PropertiesContainer,
  PropertyContainer,
  PropertyHeader,
  PropertyImg,
  PropertyText,
  PropertyTextContainer,
  RowHr,
  SecondaryHeader,
  SliderContainer,
  SpecificationContainer,
  SpecificationHeader,
  SpecificationRow,
  SpecificationSmallerContainer,
  SpecificationText,
  StyledButton,
  StyledHr,
  TablesContainer,
  Text,
} from "./machineDetailStyled";

import { ThreeDots } from "react-loader-spinner";
import iCalendar from "../../images/Machines/iCalendar.svg";
import iClock from "../../images/Machines/iClock.svg";
import iFuel from "../../images/Machines/iFuel.svg";
import iHeight from "../../images/Machines/iHeight.svg";
import iLoadCapacity from "../../images/Machines/iLoadCapacity.svg";
import iWeight from "../../images/Machines/iWeight.svg";

const RenderSpecificationRow = (props) => {
  return (
    <>
      <SpecificationRow>
        <SpecificationHeader>{props.name}</SpecificationHeader>
        <SpecificationText>{props.value}</SpecificationText>
      </SpecificationRow>
      <RowHr />
    </>
  );
};

const MachineDetail = () => {
  const [searchparams] = useSearchParams();
  const id = searchparams.get("id");
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/machine/admin/get-machineByID`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data);
      });
  }, [id]);

  let slidesArray = [];
  if (data) {
    for (let i = 0; i < data.machine.numberOfImages; i++) {
      slidesArray.push({ url: `https://storage.googleapis.com/prezentation_web_storage/${data.machine.id}/${i}` });
    }
  }
  //<TopBanner img={TopBannerImg} header={data[id].header} />

  return (
    <div>
      {data ? (
        <div>
          <Container>
            <MainDataContainer>
              <SliderContainer>
                <SmartSlider buttonShape="round" slides={slidesArray} autoSlide={false} autoSlideInterval={0} />
              </SliderContainer>
              <DataContainer>
                <HeaderContainer>
                  <Header>{data.machine.name}</Header>
                  <HeaderStyleLine />
                </HeaderContainer>
                <PropertiesContainer>
                  <div>
                    <PropertyContainer>
                      <PropertyImg src={iCalendar} />
                      <PropertyTextContainer>
                        <PropertyHeader>Rok výroby</PropertyHeader>
                        <PropertyText>{data.machine.year}</PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>

                    <PropertyContainer>
                      <PropertyImg src={iHeight} />
                      <PropertyTextContainer>
                        <PropertyHeader>Výška zdvihu</PropertyHeader>
                        <PropertyText>
                          {data.machine.liftHeight > 999 ? data.machine.liftHeight.toString().slice(0, 1) + " " + data.machine.liftHeight.toString().slice(1) : data.machine.liftHeight}mm
                        </PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>
                  </div>
                  <div>
                    <PropertyContainer>
                      <PropertyImg src={iClock} />
                      <PropertyTextContainer>
                        <PropertyHeader>Motohodiny</PropertyHeader>
                        <PropertyText>
                          {data.machine.motorHours > 999 && data.machine.motorHours < 9999
                            ? data.machine.motorHours.toString().slice(0, 1) + " " + data.machine.motorHours.toString().slice(1)
                            : data.machine.motorHours > 9999
                            ? data.machine.motorHours.toString().slice(0, 2) + " " + data.machine.motorHours.toString().slice(2)
                            : data.machine.motorHours}
                          {!data.motorHours && <PropertyText>Doplníme</PropertyText>}
                        </PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>

                    <PropertyContainer>
                      <PropertyImg src={iFuel} />
                      <PropertyTextContainer>
                        <PropertyHeader>Pohon</PropertyHeader>
                        <PropertyText>{data.machine.drive}</PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>
                  </div>
                  <div>
                    <PropertyContainer>
                      <PropertyImg src={iWeight} />
                      <PropertyTextContainer>
                        <PropertyHeader>Hmotnost</PropertyHeader>
                        <PropertyText>{data.machine.weight > 999 ? data.machine.weight.toString().slice(0, 1) + " " + data.machine.weight.toString().slice(1) : data.machine.weight}Kg</PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>

                    <PropertyContainer>
                      <PropertyImg src={iLoadCapacity} />
                      <PropertyTextContainer>
                        <PropertyHeader>Nosnost</PropertyHeader>
                        <PropertyText>
                          {data.machine.loadCapacity > 999 ? data.machine.loadCapacity.toString().slice(0, 1) + " " + data.machine.loadCapacity.toString().slice(1) : data.machine.loadCapacity}Kg
                        </PropertyText>
                      </PropertyTextContainer>
                    </PropertyContainer>
                  </div>
                </PropertiesContainer>
                <StyledHr />
                <Text>{data.machine.description}</Text>
                <PriceContainer>
                  <PriceTextContainer>
                    <PropertyHeader>Pronájem den/od: </PropertyHeader>
                    <PriceText>{data.machine.rentPrice > 999 ? data.machine.rentPrice.toString().slice(0, 1) + " " + data.machine.rentPrice.toString().slice(1) : data.machine.rentPrice} Kč</PriceText>
                  </PriceTextContainer>
                  <PriceTextContainer>
                    <PropertyHeader>Prodej (bez DPH)</PropertyHeader>
                    {data.machine.salePrice ? (
                      <PriceText>
                        {data.machine.salePrice > 99999
                          ? data.machine.salePrice?.toString().slice(0, 3) + " " + data.machine.salePrice?.toString().slice(3)
                          : data.machine.salePrice?.toString().slice(0, 2) + " " + data.machine.salePrice?.toString().slice(2)}
                        Kč
                      </PriceText>
                    ) : (
                      <PriceText>Na dotaz</PriceText>
                    )}
                  </PriceTextContainer>
                </PriceContainer>
                <StyledButton
                  onClick={() => navigate("/contact", { state: { value: "Prodej a pronájem vozíků", machine: data.machine.name, machineNumber: data.machineSpecification[0].machineNumber } })}
                  variant="contained"
                  style={{ width: "100%", backgroundColor: "#262626", marginTop: "25px", transition: "0.2s" }}
                >
                  Kontaktovat
                </StyledButton>
              </DataContainer>
            </MainDataContainer>
            <TablesContainer>
              <SpecificationContainer>
                <SecondaryHeader>Technická specifikace</SecondaryHeader>
                <RenderSpecificationRow name="Číslo stroje" value={data.machineSpecification[0].machineNumber} />
                <RenderSpecificationRow name="Typ stroje" value={data.machineSpecification[0].machineType} />
                <RenderSpecificationRow name="Pohon" value={data.machineSpecification[0].drive} />
                <RenderSpecificationRow
                  name="Nosnost"
                  value={
                    data.machine.loadCapacity > 999 ? data.machine.loadCapacity.toString().slice(0, 1) + " " + data.machine.loadCapacity.toString().slice(1) + "Kg" : data.machine.loadCapacity + "Kg"
                  }
                />
                <RenderSpecificationRow name="Rok výroby" value={data.machineSpecification[0].year} />
                <RenderSpecificationRow
                  name="Motohodiny"
                  value={
                    data.machine.motorHours > 999 && data.machine.motorHours < 9999
                      ? data.machine.motorHours.toString().slice(0, 1) + " " + data.machine.motorHours.toString().slice(1)
                      : data.machine.motorHours > 9999
                      ? data.machine.motorHours.toString().slice(0, 2) + " " + data.machine.motorHours.toString().slice(2)
                      : data.machine.motorHours
                  }
                />
                {data.machineSpecification[0].passingHeight && (
                  <RenderSpecificationRow
                    name="Průjezdová výška"
                    value={
                      data.machineSpecification[0].passingHeight > 999
                        ? data.machineSpecification[0].passingHeight.toString().slice(0, 1) + " " + data.machineSpecification[0].passingHeight.toString().slice(1) + "mm"
                        : data.machineSpecification[0].passingHeight + "mm"
                    }
                  />
                )}
                <RenderSpecificationRow
                  name="Výška zdvihu"
                  value={data.machine.liftHeight > 999 ? data.machine.liftHeight.toString().slice(0, 1) + " " + data.machine.liftHeight.toString().slice(1) + "mm" : data.machine.liftHeight + "mm"}
                />
                <RenderSpecificationRow
                  name="Hmotnost"
                  value={data.machine.weight > 999 ? data.machine.weight.toString().slice(0, 1) + " " + data.machine.weight.toString().slice(1) + "Kg" : data.machine.weight + "Kg"}
                />
                <RenderSpecificationRow name="Vidlice" value={data.machineSpecification[0].forks} />
                {data.machineSpecification[0].mastType && <RenderSpecificationRow name="Typ stožáru" value={data.machineSpecification[0].mastType} />}
                {data.machineSpecification[0].additionalAttributes && <RenderSpecificationRow name="Přídavné zařízení" value={data.machineSpecification[0].additionalAttributes} />}
                {data.machineSpecification[0].equipment && <RenderSpecificationRow name="Vybavení" value={data.machineSpecification[0].equipment} />}
                <RenderSpecificationRow name="Motor" value={data.machineSpecification[0].engine} />
                <RenderSpecificationRow name="Ovládání hydrauliky" value={data.machineSpecification[0].hydraulicControl} />
                <RenderSpecificationRow name="Ovládání směru jízdy" value={data.machineSpecification[0].driveControl} />
                <RenderSpecificationRow name="Kabina" value={data.machineSpecification[0].cabin} />
                <RenderSpecificationRow name="Světla" value={data.machineSpecification[0].lights} />
                {data.machineSpecification[0].battery && <RenderSpecificationRow name="Baterie" value={data.machineSpecification[0].battery} />}
              </SpecificationContainer>
              {data.machineAccessories[0].name1 && (
                <SpecificationSmallerContainer>
                  <SecondaryHeader>Příslušenství</SecondaryHeader>
                  {Object.keys(data.machineAccessories[0]).map(
                    (key) =>
                      key !== "id" &&
                      key !== "edges" && (
                        <div key={key}>
                          <SpecificationRow>
                            <SpecificationHeader>{data.machineAccessories[0][key]}</SpecificationHeader>
                          </SpecificationRow>
                          <RowHr />
                        </div>
                      )
                  )}
                </SpecificationSmallerContainer>
              )}
            </TablesContainer>
          </Container>
          <Footer />
        </div>
      ) : (
        <ThreeDots
          height="50"
          width="80"
          radius="9"
          color="#262626"
          ariaLabel="three-dots-loading"
          wrapperStyle={{ position: "absolute", left: "50%", top: "50%", transform: "translatex(-50%) translatey(-50%)" }}
          wrapperClassName=""
          visible={true}
        />
      )}
    </div>
  );
};

export default MachineDetail;
