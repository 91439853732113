import styled from "styled-components";
import { keyframes } from "styled-components";

import Button from "@mui/material/Button";
import colors from "../../global/colors";
import fontSize from "../../global/fontSize";

const shake = keyframes`
     0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
`;

export const StyledOrangeButton = styled(Button)`
  background-color: ${colors.orange} !important;
  text-transform: none !important;
  color: white !important;
  font-weight: bold !important;
  font-size: ${fontSize.text24} !important;
  transition: 0.2s !important;
  border: none !important;
  animation: ${shake} 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;

  :hover {
    border-color: ${colors.orange} !important;
    transition: 0.2s !important;
    opacity: 0.8 !important;
  }

  @media (max-width: 500px) {
    font-size: 16px !important;
  }
`;
