import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, Slider } from "@mui/material";
import React from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import InfiniteScroll from "react-infinite-scroll-component";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CheckSession } from "../../utils/CheckSession";
import {
  Card,
  CardHeader,
  CardsContainer,
  ImageContainer,
  MachinesContainer,
  PriceContainer,
  PriceText,
  PriceTextContainer,
  PropertiesContainer,
  PropertyContainerLeft,
  PropertyContainerRight,
  PropertyHeader,
  PropertyText,
  PropertyTextContainer,
} from "../Machines/styled";
import { AddNewMachineCard, Container, ControlsContainer, FilterContainer, FilterHamburger, FilterSectionContainer, FilterSectionHeader, FilterSectionLine } from "./styled";

import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import NoMachines from "../../components/NoMachines";
import iCalendar from "../../images/Machines/iCalendar.svg";
import iClock from "../../images/Machines/iClock.svg";
import iFuel from "../../images/Machines/iFuel.svg";
import iHeight from "../../images/Machines/iHeight.svg";
import iLoadCapacity from "../../images/Machines/iLoadCapacity.svg";
import iWeight from "../../images/Machines/iWeight.svg";
import iFilter from "../../images/filter.svg";
import { useLazyLoading } from "../../network";

function AdminMachines() {
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    limit: 20,
    type1: "",
    type2: "",
    type3: "",
    drive1: "",
    drive2: "",
    drive3: "",
    yearFrom: "0",
    yearTo: new Date().getFullYear().toString(),
    order: "4",
    salePriceFrom: "0",
    salePriceTo: "2000000",
  });
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showFilterHamburger, setShowFilterHamburger] = useState(false);
  const [price, setPrice] = useState([0, 2000000]);
  const [year, setYear] = useState([0, new Date().getFullYear()]);
  let { data, size, setSize, isReachedEnd } = useLazyLoading("/machine/admin/get-machines", options);
  data = data?.flat();

  window.addEventListener("resize", () => {
    if (window.innerWidth <= 800) {
      setShowFilterHamburger(true);
    } else {
      setShowFilterHamburger(false);
    }
  });

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setShowFilterHamburger(true);
    } else {
      setShowFilterHamburger(false);
    }
  }, []);

  const openMachine = (id) => {
    navigate({ pathname: "/administration/machines/new-machine", search: createSearchParams({ id: id }).toString() });
  };

  const updatePriceRange = (e, data) => {
    setPrice(data);
  };

  const updatePriceRangeAndSetOption = (e, data) => {
    setOptions({ ...options, salePriceFrom: data[0], salePriceTo: data[1] });
  };

  const priceSliderValueText = (value) => {
    return `${value} Kč`;
  };

  const priceSliderValueFormat = (value) => {
    if (value > 999 && value < 9999) {
      value = value.toString()[0] + " " + value.toString().slice(1);
    } else if (value > 999 && value < 99999) {
      value = value.toString().slice(0, 2) + " " + value.toString().slice(2);
    } else {
      value = value.toString().slice(0, 3) + " " + value.toString().slice(3);
    }
    return `${value} Kč`;
  };

  const updateYearRange = (e, data) => {
    setYear(data);
  };

  const updateYearRangeAndSetOption = (e, data) => {
    setOptions({ ...options, yearFrom: data[0], yearTo: data[1] });
  };

  return (
    CheckSession() && (
      <>
        {showFilterHamburger && (
          <FilterHamburger onClick={() => setShowFilterMenu(!showFilterMenu)}>
            <img src={iFilter} alt="Obrázek znázorňující filtrování" />
          </FilterHamburger>
        )}
        <FilterContainer show={showFilterMenu}>
          <FilterSectionContainer>
            <FilterSectionHeader>Seřadit</FilterSectionHeader>
            <FilterSectionLine></FilterSectionLine>
            <ControlsContainer>
              <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                <FormControlLabel
                  value="4"
                  onChange={(e) => setOptions((options) => ({ ...options, order: `${e.target.checked && "4"}` }))}
                  control={<Radio style={{ color: "#747272", height: "30px" }} />}
                  label="Od nejnovějšího"
                />
                <FormControlLabel
                  value="0"
                  onChange={(e) => setOptions((options) => ({ ...options, order: `${e.target.checked && "0"}` }))}
                  control={<Radio style={{ color: "#747272", height: "30px" }} />}
                  label="Od nejlevnějších"
                />
                <FormControlLabel
                  value="1"
                  onChange={(e) => setOptions((options) => ({ ...options, order: `${e.target.checked && "1"}` }))}
                  control={<Radio style={{ color: "#747272", height: "30px" }} />}
                  label="Od nejdražších"
                />
                <FormControlLabel
                  value="2"
                  onChange={(e) => setOptions((options) => ({ ...options, order: `${e.target.checked && "2"}` }))}
                  control={<Radio style={{ color: "#747272", height: "30px" }} />}
                  label="MTH vzestupně"
                />
                <FormControlLabel
                  value="3"
                  onChange={(e) => setOptions((options) => ({ ...options, order: `${e.target.checked && "3"}` }))}
                  control={<Radio style={{ color: "#747272", height: "30px" }} />}
                  label="MTH sestupně"
                />
              </RadioGroup>
            </ControlsContainer>
          </FilterSectionContainer>

          <FilterSectionContainer>
            <FilterSectionHeader>Typ vozíku</FilterSectionHeader>
            <FilterSectionLine />
            <ControlsContainer>
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, type1: `${e.target.checked ? "Retrak" : ""}` }))} />}
                label={"Retrak"}
              />
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, type2: `${e.target.checked ? "Čelní vozík" : ""}` }))} />}
                label={"Čelní vozík"}
              />
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, type3: `${e.target.checked ? "Ručně vedený" : ""}` }))} />}
                label={"Ručně vedený"}
              />
            </ControlsContainer>
          </FilterSectionContainer>

          <FilterSectionContainer>
            <FilterSectionHeader>Pohon vozíku</FilterSectionHeader>
            <FilterSectionLine />
            <ControlsContainer>
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, drive1: `${e.target.checked ? "AKU" : ""}` }))} />}
                label={"AKU"}
              />
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, drive2: `${e.target.checked ? "Diesel" : ""}` }))} />}
                label={"Diesel"}
              />
              <FormControlLabel
                control={<Checkbox style={{ color: "#747272", height: "30px" }} onChange={(e) => setOptions((options) => ({ ...options, drive3: `${e.target.checked ? "LPG" : ""}` }))} />}
                label={"LPG"}
              />
            </ControlsContainer>
          </FilterSectionContainer>

          <FilterSectionContainer>
            <FilterSectionHeader>Prodejní cena</FilterSectionHeader>
            <FilterSectionLine />
            <ControlsContainer>
              <Slider
                min={0}
                max={500000}
                value={price}
                onChange={updatePriceRange}
                onChangeCommitted={updatePriceRangeAndSetOption}
                getAriaValueText={priceSliderValueText}
                valueLabelFormat={priceSliderValueFormat}
                valueLabelDisplay="auto"
                disableSwap
                style={{ color: "#262626" }}
              />
            </ControlsContainer>
          </FilterSectionContainer>

          <FilterSectionContainer>
            <FilterSectionHeader>Rok výroby</FilterSectionHeader>
            <FilterSectionLine />
            <ControlsContainer>
              <Slider
                min={1980}
                max={new Date().getFullYear()}
                value={year}
                onChange={updateYearRange}
                onChangeCommitted={updateYearRangeAndSetOption}
                valueLabelDisplay="auto"
                disableSwap
                style={{ color: "#262626" }}
              />
            </ControlsContainer>
          </FilterSectionContainer>
          <FilterSectionContainer>
            <Button variant="outlined" fullWidth style={{ color: "#000", borderColor: "#262626", marginBottom: "20px" }} onClick={() => window.location.reload()}>
              Zrušit filtry
            </Button>
          </FilterSectionContainer>
        </FilterContainer>

        <Container>
          <MachinesContainer style={{ marginTop: "100px" }}>
            {data ? (
              <InfiniteScroll
                style={{ display: "unset" }}
                next={() => setSize(size + 1)}
                hasMore={!isReachedEnd}
                dataLength={Object.values(data).length ?? 0}
                loader={
                  <ThreeDots
                    height="50"
                    width="80"
                    radius="9"
                    color="#262626"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ position: "absolute", left: "50%", transform: "translatex(-50%)" }}
                    wrapperClassName=""
                    visible={true}
                  />
                }
              >
                {data && Object.values(data).length === 0 ? (
                  <NoMachines />
                ) : (
                  <CardsContainer>
                    <AddNewMachineCard onClick={() => navigate("/administration/machines/new-machine")}>
                      <AiFillPlusCircle style={{ fontSize: "8rem", color: "#262626" }} />
                    </AddNewMachineCard>
                    {data &&
                      data?.map((machine, index) => (
                        <Card key={index} onClick={() => openMachine(machine.id)} style={{ opacity: machine.hidden ? "0.6" : "1" }}>
                          <ImageContainer style={{ backgroundImage: `url(https://storage.googleapis.com/prezentation_web_storage/${machine.id}/0)` }} />
                          <CardHeader>{machine.name}</CardHeader>
                          <PropertiesContainer>
                            <PropertyContainerLeft>
                              <img src={iCalendar} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Rok výroby</PropertyHeader>
                                <PropertyText>{machine.year}</PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerLeft>
                            <PropertyContainerRight>
                              <img src={iHeight} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Výška zdvihu</PropertyHeader>
                                <PropertyText>
                                  {machine.liftHeight > 999 ? machine.liftHeight.toString().slice(0, 1) + " " + machine.liftHeight.toString().slice(1) : machine.liftHeight}mm
                                </PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerRight>
                            <PropertyContainerLeft>
                              <img src={iClock} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Motohodiny</PropertyHeader>
                                <PropertyText>
                                  {machine.motorHours > 999 && machine.motorHours < 9999
                                    ? machine.motorHours.toString().slice(0, 1) + " " + machine.motorHours.toString().slice(1)
                                    : machine.motorHours > 9999
                                    ? machine.motorHours.toString().slice(0, 2) + " " + machine.motorHours.toString().slice(2)
                                    : machine.motorHours}
                                  {!machine.motorHours && <PropertyText>Doplníme</PropertyText>}
                                </PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerLeft>
                            <PropertyContainerRight>
                              <img src={iFuel} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Pohon</PropertyHeader>
                                <PropertyText>{machine.drive}</PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerRight>

                            <PropertyContainerLeft>
                              <img src={iWeight} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Hmotnost</PropertyHeader>
                                <PropertyText>{machine.weight > 999 ? machine.weight.toString().slice(0, 1) + " " + machine.weight.toString().slice(1) : machine.weight}Kg</PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerLeft>
                            <PropertyContainerRight>
                              <img src={iLoadCapacity} alt="" />
                              <PropertyTextContainer>
                                <PropertyHeader>Nosnost</PropertyHeader>
                                <PropertyText>
                                  {machine.loadCapacity > 999 ? machine.loadCapacity.toString().slice(0, 1) + " " + machine.loadCapacity.toString().slice(1) : machine.loadCapacity}Kg
                                </PropertyText>
                              </PropertyTextContainer>
                            </PropertyContainerRight>
                          </PropertiesContainer>
                          <PriceContainer>
                            <PriceTextContainer>
                              <PropertyHeader>Pronájem /den od:</PropertyHeader>
                              <PriceText>
                                {machine.rentPrice > 999 ? machine.rentPrice.toString().slice(0, 1) + " " + machine.rentPrice.toString().slice(1) : machine.rentPrice}
                                Kč
                              </PriceText>
                            </PriceTextContainer>
                            <PriceTextContainer>
                              <PropertyHeader>Prodej (bez DPH):</PropertyHeader>
                              {machine.salePrice ? (
                                <PriceText>
                                  {machine.salePrice > 99999
                                    ? machine.salePrice?.toString().slice(0, 3) + " " + machine.salePrice?.toString().slice(3)
                                    : machine.salePrice?.toString().slice(0, 2) + " " + machine.salePrice?.toString().slice(2)}
                                  Kč
                                </PriceText>
                              ) : (
                                <PriceText>Na dotaz</PriceText>
                              )}
                            </PriceTextContainer>
                          </PriceContainer>
                          <Button variant="contained" style={{ width: "95%", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}>
                            Detail
                          </Button>
                        </Card>
                      ))}
                  </CardsContainer>
                )}
              </InfiniteScroll>
            ) : (
              <ThreeDots
                height="50"
                width="80"
                radius="9"
                color="#262626"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ position: "absolute", left: "50%", top: "50%", transform: "translatex(-50%) translatey(-50%)" }}
                wrapperClassName=""
                visible={true}
              />
            )}
          </MachinesContainer>
        </Container>
      </>
    )
  );
}

export default AdminMachines;
