import { Container, Form } from "./styled";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GetSession } from "../../utils/CheckSession";

const CheckSession = async () => {
  const navigate = useNavigate();

  if (GetSession() !== null) {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/auth/check-session`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "token " + GetSession() },
      });

      if (res.status === 200) {
        navigate("/administration");
      } else {
        localStorage.removeItem("session");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const Login = () => {
  const navigate = useNavigate();
  CheckSession();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/auth/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });

        if (res.status === 200) {
          const user = await res.json();
          localStorage.setItem("session", user.session);
          navigate("/administration");
        } else {
          alert("Špatné uživatelské údaje");
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    },
  });

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          name="email"
          style={{ marginTop: "15px" }}
          type={"email"}
          variant="outlined"
          label="E-mail"
          required
          fullWidth
        ></TextField>
        <TextField
          value={formik.values.password}
          onChange={formik.handleChange}
          name="password"
          style={{ marginTop: "15px" }}
          type={"password"}
          variant="outlined"
          label="Heslo"
          required
          fullWidth
        ></TextField>
        <Button type="submit" variant="contained" style={{ width: "100%", backgroundColor: "#262626", marginTop: "15px", transition: "0.2s" }}>
          Odeslat
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
