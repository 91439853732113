import styled from "styled-components";

export const NoMachinesContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  margin: 0 auto;
  width: 320px;
  height: 550px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0 40px;

  @media (max-width: 800px) {
    width: 250px;
    margin-left: 0;
  }
`;

export const Image = styled.img`
  height: 150px;
  margin-bottom: 20px;
`;

export const NoMachinesHeader = styled.h4`
  color: #262626;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const NoMachinesLabel = styled.p`
  color: #262626;
  font-size: 16px;
`;
